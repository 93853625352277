* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container {
  background-color: #f5f5f5;
  height: 100vh;
  position: fixed;
  width: 100vw;
}
.page-wrapper {
  display: flex;
  flex-direction: row;
}
.subContainer {
  padding: 1rem 1rem;
  width: 100%;
  padding-bottom: 4rem;
  overflow-y: scroll;
  height: 95vh;
}

::-webkit-scrollbar {
  width: 0.4vw;
  height: 0.4vw;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}